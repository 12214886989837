import axios from 'axios';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST_API_KEY || `https://petrosolutionsback.invoices.solutions/api`,
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('accessToken');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const json = error.response.data;
    let message = json.message;

    if (error.response.status === 401) {
    } else if (error.response.status === 403 && message === 'Invalid scope(s) provided.') {
      removeLocalStorage();

      window.location.href = '/auth/sign-in';
    } else if (error.response.status === 404) {
      message = 'El recurso no existe';
    }
    return Promise.reject(error.response);
  }
);
function removeLocalStorage() {
  localStorage.removeItem('accessToken');
}
function apiGet(urlCode, getFilters = null, oid = null, contactId = null) {
  let url = urlCode != null ? apiUrl[urlCode] : '';

  if (url == null || url.length === 0) {
    console.log('!!! GET ERROR : No url code found');
    return '';
  }

  if (oid && url.indexOf('{oid}') > 0) {
    url = url.replace('$', '');
    const temp = url.split('{oid}');
    url = `${temp[0]}${oid}${temp[1]}`;
  }

  if (contactId && url.indexOf('{contact_id}') > 0) {
    url = url.replace('$', '');
    const temp = url.split('{contact_id}');
    url = temp[0] + contactId + temp[1];
  }

  let include = [];
  let params = [];
  let filters = [];
  let page = 0;
  let limit = 0;
  let search = null;
  let sort = null;

  if (getFilters != null) {
    include = getFilters.include != null ? getFilters.include : include;
    page = getFilters.page != null ? getFilters.page : page;
    limit = getFilters.limit != null ? getFilters.limit : limit;
    params = getFilters.params != null ? getFilters.params : params;
    filters = getFilters.filters != null ? getFilters.filters : filters;
    search = getFilters.search != null ? getFilters.search : search;
    sort = getFilters.sort != null ? getFilters.sort : sort;
  }
  let urlPart = '';
  let operator = '';
  if (include != null && include.length > 0) {
    include.forEach((element) => {
      operator = operator === '' ? '?' : '&';
      urlPart += operator === '?' ? '?include=' : ',';
      urlPart += element;
    });
  }
  if (page != null && page > 0) {
    operator = operator === '' ? '?' : '&';

    urlPart += `${operator}page=${page}`;
  }
  if (limit != null && limit > 0) {
    operator = operator === '' ? '?' : '&';

    urlPart += `${operator}limit=${limit}`;
  }

  if (search != null && search.length > 0) {
    operator = operator === '' ? '?' : '&';

    urlPart += `${operator}search=${search}`;
  }

  if (sort != null && sort.length > 0) {
    operator = operator === '' ? '?' : '&';

    urlPart += `${operator}sort=${sort}`;
  }
  url += urlPart;

  url = setParamsAndFiltersUrl(url, params, operator, filters);

  return instance.get(url);
}

function apiPost(urlCode, payload, getFilters = null, op = 'post') {
  let url = urlCode != null ? apiUrl[urlCode] : '';

  if (url === null || url.length === 0) {
    console.log('!!! POST ERROR : No url code found');
    return '';
  }

  let params = [];
  if (getFilters != null) {
    params = getFilters.params != null ? getFilters.params : params;
  }

  url = setParamsAndFiltersUrl(url, params, '');

  return op === 'post' ? instance.post(url, payload) : instance.put(url, payload);
}

function apiDelete(urlCode, getFilters = null) {
  let url = urlCode != null ? apiUrl[urlCode] : '';

  if (url === null || url.length === 0) {
    console.log('!!! DELETE ERROR : No url code found');
    return '';
  }

  let params = [];
  if (getFilters != null) {
    params = getFilters.params != null ? getFilters.params : params;
  }

  url = setParamsAndFiltersUrl(url, params, '');

  return instance.delete(url);
}

function setParamsAndFiltersUrl(url, params, operator, filters = null) {
  if (params != null) {
    Object.keys(params).forEach((key) => {
      const value = params[key];
      let find = '${';
      find += key;
      find += '}';

      if (url.includes(find)) {
        url = url.replace(find, value);
      } else {
        operator = operator === '' ? '?' : '&';
        url += `${operator}${key}=${value}`;
      }
    });
  }
  if (filters != null) {
    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      key = key.replace('___', '.');
      const str = `filter[${key}]=${value}`;
      operator = operator === '' ? '?' : '&';
      url += operator + str;
    });
  }
  return url;
}

const apiUrl = {
  /* stockItemParts */
  GET_STOCKITEMPARTS: '/organizations/${oid}/stockItemParts',
  GET_CALLHOLDREASONSINDEX: '/stockItemParts',
  POST_STOCKITEMPART: '/stockItemParts',
  PUT_STOCKITEMPART: '/stockItemParts/${id}',
  DELETE_STOCKITEMPART: '/stockItemParts/${id}',

  /* callStatusDropdown */
  GET_CALLSTATUSDROPDOWN: '/callstatusdropdown',

  /* organizationsDropdown */
  GET_ORGANIZATIONSDROPDOWN: '/organizationsdropdown',

  /* billingcalls */
  GET_ORGANIZATIONBILLINGCALLS: '/organizations/${oid}/billingcalls',

  /* liveNotifications */

  GET_LIVENOTIFICATIONS: '/users/${oid}/usernotifications',

  /* organizations */

  GET_ORGANIZATIONS: '/organizations',
  POST_ORGANIZATION: '/organizations',
  PUT_ORGANIZATION: '/organizations/${id}',
  DELETE_ORGANIZATION: '/organizations/${id}',

  GET_ORGANIZATIONSCALLSREPORT: '/organizations/${oid}/callsReport',
  GET_ORGANIZATIONSPENDINGONCALL: '/organizations/${oid}/pendingoncall',
  GET_ORGANIZATIONSPENDINGAPPROVAL: '/organizations/${oid}/pendingapproval',
  GET_ORGANIZATIONSONREVIEW: '/organizations/${oid}/onreviewcalls',

  /* callHoldReasons */
  GET_CALLHOLDREASONS: '/organizations/${oid}/callHoldReasons',
  GET_CALLHOLDREASONSINDEX: '/callholdreasons',
  POST_CALLHOLDREASON: '/callholdreasons',
  PUT_CALLHOLDREASON: '/callholdreasons/${id}',
  DELETE_CALLHOLDREASON: '/callholdreasons/${id}',

  /* locationCalls */
  GET_LOCATIONCALLS: '/customerlocations/${oid}/calls',
  POST_LOCATIONCALLS: '/calls',
  PUT_LOCATIONCALLS: '/calls/${id}',
  DELETE_LOCATIONCALLS: '/calls/${id}',

  /* calls */
  GET_CUSTOMERCALLS: '/customers/${oid}/calls',
  GET_CALLSNO: '/callsnocompleted',
  GET_CALLS: '/calls',
  POST_CALL: '/calls',
  PUT_CALL: '/calls/${id}',
  DELETE_CALL: '/calls/${id}',

  /* contacts */
  GET_LOCATIONCONTACTS: '/customers/${oid}/customerlocationcontacts',
  GET_LOCATIONSCONTACTS: '/locationcontacts',
  POST_LOCATIONCONTACT: '/locationcontacts',
  PUT_LOCATIONCONTACT: '/locationcontacts/${id}',
  DELETE_LOCATIONCONTACT: '/locationcontacts/${id}',

  /* customerLocations */
  GET_CUSTOMERLOCATIONS: '/customers/${oid}/customerlocations',
  GET_CUSTOMERSLOCATIONS: '/organizations/${oid}/customerLocations',
  GET_CUSTOMERSLOCATIONSINDEX: '/customerlocations',
  POST_CUSTOMERLOCATION: '/customerlocations',
  PUT_CUSTOMERLOCATION: '/customerlocations/${id}',
  DELETE_CUSTOMERLOCATION: '/customerlocations/${id}',

  /* customers */
  GET_CUSTOMERS: '/organizations/${oid}/customers',
  GET_CUSTOMERSINDEX: '/customers',
  POST_CUSTOMER: '/customers',
  PUT_CUSTOMER: '/customers/${id}',
  DELETE_CUSTOMER: '/customers/${id}',

  /* callTypes */
  GET_CALLTYPES: '/organizations/${oid}/callTypes',
  GET_CALLTYPESINDEX: '/calltypes',
  POST_CALLTYPE: '/calltypes',
  PUT_CALLTYPE: '/calltypes/${id}',
  DELETE_CALLTYPE: '/calltypes/${id}',

  /* callProblemTypes */
  GET_CALLPROBLEMTYPES: '/organizations/${oid}/callProblemTypes',
  GET_CALLPROBLEMTYPESINDEX: '/callproblemtypes',
  POST_CALLPROBLEMTYPE: '/callproblemtypes',
  PUT_CALLPROBLEMTYPE: '/callproblemtypes/${id}',
  DELETE_CALLPROBLEMTYPE: '/callproblemtypes/${id}',

  /* permissions */
  GET_PERMISSIONS: '/permissions',

  /* roles */
  GET_ROLES: '/roles',
  POST_ROLE: '/roles',
  PUT_ROLE: '/roles/${id}',
  DELETE_ROLE: '/roles/${id}',

  /* departments */
  GET_DEPARTMENTS: '/organizations/${oid}/departments',
  GET_DEPARTMENTSINDEX: '/departments',
  POST_DEPARTMENT: '/departments',
  PUT_DEPARTMENT: '/departments/${id}',
  DELETE_DEPARTMENT: '/departments/${id}',

  // Dropdowns

  GET_DEPARTMENTSDROPDOWN: '/departmentsdropdown',

  GET_USERSDROPDOWN: '/usersdropdown',
  GET_CUSTOMERLOCATIONSDROPDOWN: '/customers/${id}/customerlocationsdropdown',

  /* contacts 
  GET_CONTACTS: '/organizations/${oid}/contacts',
  GET_CONTACTSINDEX: '/contacts',
  POST_CONTACT: '/contacts',
  PUT_CONTACT: '/contacts/${id}',
  DELETE_CONTACT: '/contacts/${id}', */

  /* users */
  GET_USERS: '/organizations/${oid}/users',
  GET_USERSINDEX: '/users',
  GET_USER: '/users/${id}',
  POST_USER: '/users',
  PUT_USER: '/users/${id}',
  DELETE_USER: '/users/${id}',
};

const Api = {
  changeCallLocation: function (id, payload) {
    return instance.post(`/organizations/${id}/changecalllocation`, payload);
  },
  // Get One

  getImage: function (url) {
    return instance.get(url);
  },
  getUser: function (id) {
    return instance.get(`/users/${id}`);
  },

  addUser: function (payload) {
    return instance.post(`/users`, payload);
  },

  updateUser: function (id, payload) {
    return instance.post(`/users/${id}`, payload);
  },

  deleteUser: function (id) {
    return instance.delete(`/users/${id}`);
  },

  getContact: function (id) {
    return instance.get(`/contacts/${id}`);
  },
  getDepartment: function (id) {
    return instance.get(`/departments/${id}`);
  },

  getRole: function (id) {
    return instance.get(`/roles/${id}`);
  },

  addRole: function (payload) {
    return instance.post(`/roles`, payload);
  },

  updateRole: function (id, payload) {
    return instance.post(`/roles/${id}`, payload);
  },

  deleteRole: function (id) {
    return instance.delete(`/roles/${id}`);
  },

  getAppointment: function (id) {
    return instance.get(`/appointments/${id}`);
  },

  createContactComment: function (id, payload) {
    return instance.post(`/contacts/${id}/storecomment`, payload);
  },

  getCallProblemType: function (id) {
    return instance.get(`/callproblemtypes/${id}`);
  },

  getCallType: function (id) {
    return instance.get(`/calltypes/${id}`);
  },

  getCustomer: function (id) {
    return instance.get(`/customers/${id}`);
  },
  addCustomer: function (payload) {
    return instance.post(`/customers`, payload);
  },

  updateCustomer: function (id, payload) {
    return instance.post(`/customers/${id}`, payload);
  },

  deleteCustomer: function (id) {
    return instance.delete(`/customers/${id}`);
  },

  getCustomerInclude: function (id) {
    return instance.get(`/customers/${id}?include=contacts,locations`);
  },
  getCustomerLocation: function (id) {
    return instance.get(`/customerlocations/${id}`);
  },
  getLocationContact: function (id) {
    return instance.get(`/locationcontacts/${id}`);
  },

  createLocationContact: function (payload) {
    return instance.post(`/locationcontacts`, payload);
  },

  getCallHoldReasons: function (id) {
    return instance.get(`/callholdreasons/${id}`);
  },

  addCallHoldReasons: function (payload) {
    return instance.post(`/callholdreasons`, payload);
  },

  updateCallHoldReasons: function (id, payload) {
    return instance.post(`/callholdreasons/${id}`, payload);
  },

  deleteCallHoldReasons: function (id) {
    return instance.delete(`/callholdreasons/${id}`);
  },

  getCallProblemTypes: function (id) {
    return instance.get(`/callproblemtypes/${id}`);
  },

  addCallProblemType: function (payload) {
    return instance.post(`/callproblemtypes`, payload);
  },

  updateCallProblemType: function (id, payload) {
    return instance.post(`/callproblemtypes/${id}`, payload);
  },

  deleteCallProblemType: function (id) {
    return instance.delete(`/callproblemtypes/${id}`);
  },

  getCallTypes: function (id) {
    return instance.get(`/calltypes/${id}`);
  },

  addCallType: function (payload) {
    return instance.post(`/calltypes`, payload);
  },

  updateCallType: function (id, payload) {
    return instance.post(`/calltypes/${id}`, payload);
  },

  deleteCallType: function (id) {
    return instance.delete(`/calltypes/${id}`);
  },

  getStockItemParts: function (id) {
    return instance.get(`/stockItemParts/${id}`);
  },

  addStockItemPart: function (payload) {
    return instance.post(`/stockItemParts`, payload);
  },

  updateStockItemPart: function (id, payload) {
    return instance.post(`/stockItemParts/${id}`, payload);
  },

  deleteStockItemPart: function (id) {
    return instance.delete(`/stockItemParts/${id}`);
  },

  addDepartment: function (payload) {
    return instance.post(`/departments`, payload);
  },

  updateDepartment: function (id, payload) {
    return instance.post(`/departments/${id}`, payload);
  },

  deleteDepartment: function (id) {
    return instance.delete(`/departments/${id}`);
  },

  getOrganizations: function (id) {
    return instance.get(`/organizations/${id}`);
  },

  addOrganization: function (payload) {
    return instance.post(`/organizations`, payload);
  },

  updateOrganization: function (id, payload) {
    return instance.post(`/organizations/${id}`, payload);
  },

  deleteOrganization: function (id) {
    return instance.delete(`/organizations/${id}`);
  },

  getCustomerCall: function (id) {
    return instance.get(`/calls/${id}`);
  },

  getCustomerLocationsDropdown: function (id) {
    return instance.get(`/customers/${id}/customerlocationsdropdown`);
  },

  getCustomerLocationsContactsDropdown: function (id) {
    return instance.get(`/customers/${id}/customerlocationcontactsdropdown`);
  },

  getCreatedCalls: function (id) {
    return instance.get(`/createdcalls`);
  },
  getNocompletedCalls: function (id) {
    return instance.get(`/callsnocompleted`);
  },

  getTechUsersWCalls: function () {
    return instance.get(`/techuserswcalls`);
  },

  updateCallTech: function (id, payload) {
    return instance.put(`/calls/${id}`, payload);
  },

  addCallTech: function (id, payload) {
    return instance.post(`/addcalltech/${id}`, payload);
  },
  restoreCancelCall: function (id) {
    return instance.put(`/calls/${id}/restorecancel`);
  },
  restoreCompletedCall: function (id) {
    return instance.put(`/calls/${id}/restorecompleted`);
  },

  multiTechnicians: function (id, payload) {
    return instance.post(`/multitechnicians/${id}`, payload);
  },

  unReAssingTech: function (id, payload) {
    return instance.put(`/unassingtech/${id}`, payload);
  },

  updateCall: function (id, payload) {
    return instance.post(`/calls/${id}`, payload);
  },

  deleteCall: function (id) {
    return instance.delete(`/calls/${id}`);
  },

  completeCall: function (id, payload) {
    return instance.post(`/calls/${id}/completecall`, payload);
  },

  completeCallPictures: function (id, payload) {
    return instance.post(`/calls/${id}/completecallpictures`, payload);
  },

  notifiedTechnician: function (id, call_id) {
    return instance.post(`/notifiedtechnician/${id}/call/${call_id}`);
  },

  getLocationCustomers: function (id) {
    return instance.get(`/customerlocations/${id}/locationcontactsdropdown`);
  },

  notifiedAllTechnician: function (id, payload) {
    return instance.post(`/users/${id}/notifiedall`, payload);
  },
  notifiedAllTechnicians: function () {
    return instance.post(`/usersnotifiedalltechnicians`);
  },

  getOrganization: function (id) {
    return instance.get(`/organizations/${id}`);
  },

  getCallsReport: function (id, params) {
    return instance.get(`/organizations/${id}/callsReport${params ? params : '1=1'}`);
  },

  getCallForm: function (id) {
    return instance.get(`/calls/${id}/form`);
  },

  getCallHistory: function (id) {
    return instance.get(`/calls/${id}/callstatushistoryphoneform`);
  },

  getCallHistory: function (id) {
    return instance.get(`/calls/${id}/callstatushistoryphoneform`);
  },

  updateStatusHistories: function (id, payload) {
    return instance.post(`/calls/${id}/updateStatusHistories`, payload);
  },

  getCallPrint: function (id) {
    return instance.get(`/calls/${id}/callprint`);
  },

  getDashboardData: function (id, start, end, tech_id) {
    return instance.get(
      `/organizations/${id}/dashboarddata?filter[date_between]=${start},${end}&filter[tech_id]=${tech_id}`
    );
  },
  getOrganizationsDropdown: function () {
    return instance.get(`/organizationsdropdown`);
  },
  changeOrganization: function (id, payload) {
    return instance.post(`/users/${id}/changeorganization`, payload);
  },

  deleteCallFormMedia: function (id, media_id) {
    return instance.delete(`/calls/${id}/form_media/${media_id}`);
  },

  deleteUsedPart: function (id) {
    return instance.delete(`/callformusedparts/${id}`);
  },

  addUsedPart: function (payload) {
    return instance.post(`/callformusedparts`, payload);
  },

  editUsedPart: function (id, payload) {
    return instance.put(`/callformusedparts/${id}`, payload);
  },
  addCallHistory: function (payload) {
    return instance.post(`/callhistories`, payload);
  },

  deleteCallHistory: function (id) {
    return instance.delete(`/callhistories/${id}`);
  },

  getCallStatusHistoryPhoneForm: function (id) {
    return instance.get(`/calls/${id}/callstatushistoryphoneform`);
  },

  revokeAccesToken: function (id) {
    return instance.post(`/users/${id}/tokensruser`);
  },

  getCustomerToTransfer: function (payload) {
    return instance.post(`/customersearch`, payload);
  },

  transferLocationCustomer: function (id, payload) {
    return instance.post(`customerlocations/${id}/transferlocationcustomer`, payload);
  },

  getStockItemPart: function (id) {
    return instance.get(`/stockItemParts/${id}`);
  },

  // Generic Calls
  getEntities: function (apiParams) {
    return apiGet(apiParams.dataUrl.code, apiParams.filters, apiParams.dataUrl.oid ? apiParams.dataUrl.oid : null);
  },
  getContactEntities: function (apiParams) {
    return apiGet(
      apiParams.dataUrl.code,
      apiParams.filters,
      apiParams.dataUrl.oid ? apiParams.dataUrl.oid : null,
      apiParams.dataUrl.contact_id ? apiParams.dataUrl.contact_id : null
    );
  },
  saveEntity: function (apiParams) {
    return apiPost(apiParams.dataUrl.code, apiParams.payload, apiParams.filters, 'post');
  },
  updateEntity: function (apiParams) {
    return apiPost(apiParams.dataUrl.code, apiParams.payload, apiParams.filters, 'put');
  },
  deleteEntity: function (apiParams) {
    return apiDelete(apiParams.dataUrl.code, apiParams.filters);
    // return instance.delete(apiParams.dataUrl.code);
  },
  updateEntityPost: function (apiParams) {
    return apiPost(apiParams.dataUrl.code, apiParams.payload, apiParams.filters, 'post');
  },
  registerToken: function (id, payload) {
    return instance.post(`/users/${id}/tokens`, payload);
  },

  importContacts: function (payload) {
    return instance.post(`/contactsImportcsv`, payload);
  },

  registerToken: function (id, payload) {
    return instance.post(`/users/${id}/tokens`, payload);
  },

  unregisterToken: function (id, token, os) {
    return instance.delete(`/users/${id}/tokens/${token}/os/${os}`);
  },
  liveNotificationsMarkAllAsRead: function (id) {
    return instance.get(`/users/${id}/livenotificationsmarkallasread`);
  },

  getOnCalls: function (id, payload) {
    return instance.post(`/organizations/${id}/getoncalls`, payload);
  },
  storeOnCalls: function (payload) {
    return instance.post(`/oncalltechs`, payload);
  },
  updateOnCalls: function (id, payload) {
    return instance.put(`/oncalltechs/${id}`, payload);
  },

  searchLocation: function (id, search) {
    return instance.get(`/organizations/${id}/searchlocation?search=${search}`);
  },
  searchLocationNew: function (id, search) {
    return instance.get(`/organizations/${id}/searchlocationnew?search=${search}`);
  },
  getUserLog: function (id, page, limit) {
    return instance.get(`/users/${id}/activitiesLogs?page=${page ? page : 0}&limit=${limit ? limit : 10}`);
  },
  getCallLog: function (id, page, limit) {
    return instance.get(`/calls/${id}/activitiesLogs?page=${page ? page : 0}&limit=${limit ? limit : 10}`);
  },
  getCustomerLog: function (id, page, limit) {
    return instance.get(`/customers/${id}/activitiesLogs?page=${page ? page : 0}&limit=${limit ? limit : 10}`);
  },
  getLocationLog: function (id, page, limit) {
    return instance.get(`/customerlocations/${id}/activitiesLogs?page=${page ? page : 0}&limit=${limit ? limit : 10}`);
  },
  getContactLog: function (id, page, limit) {
    return instance.get(`/locationcontacts/${id}/activitiesLogs?page=${page ? page : 0}&limit=${limit ? limit : 10}`);
  },
};

export default Api;
